// TODO: Move this to its own bundle?
.page-sohp {
  h1 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 3rem;
    line-height: 3.5rem;

    @include media-breakpoint-up(md) {
      font-size: 3.5rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 4rem;
    }
  }

  .divider-line {
    background-color: $black;
    border-radius: 1rem;
    height: 0.25rem;
    max-width: 7rem;
    width: 100%;
  }

  .program-list {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }

    a {
      width: 80%;

      @include media-breakpoint-up(md) {
        width: 220px;
      }
    }
  }
}

.page-program {
  .hero-container-wrapper {
    background-color: $program-page-blue;

    img {
      width: 160px;

      @include media-breakpoint-up(md) {
        width: 180px;
      }
    }
  }

  .hero-container {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  .card {
    border-color: var(--#{$prefix}warning);
  }
}
